import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom/dist";

const SpaFitness = (props) => {
  const tabFilters = ["All", "Exterior", "Interior", "Rooms", "Meeting & Events", "Dining", "Spa"];

  return (
    <div className="restaurant">
      <Header />
      <section class="hero hero_dining">
        <div class="container">
          <div class="hero_main">
            <div class="section_header">
              <img className="spa_logo" src="/img/spa_logo.svg" />
              <p className="aura_text ">{props.strings["text_117"]}</p>
              <div class="wrapper d-flex">
                <span data-aos="fade-up">
                  <a
                    class="btn "
                    href="https://oceanclub.com.tr/ankara/"
                    target="_blank"
                    className="qr_menu btn"
                  >
                    {props.strings["text_343"]}
                  </a>
                </span>
                <span data-aos="fade-up">
                  <Link
                    key="Spa"
                    class="btn "
                    to={{ pathname: "/gallery", search: "?tab=Spa" }}
                    className="auro_gallery btn"
                  >
                    {props.strings["text_6"]}
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <Swiper
            modules={[Navigation]}
            loop={true}
            className="hero_slider swiper"
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
          >
            {" "}
            <SwiperSlide>
              <picture>
                <source
                  data-srcset="/img/spa_03.webp"
                  srcSet="/img/spa_03.webp"
                  type="image/webp"
                />
                <img
                  className="lazy"
                  data-src="/img/spa_03.webp"
                  src="/img/spa_03.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>{" "}
            <SwiperSlide>
              <picture>
                <source
                  data-srcset="/img/spa_02.webp"
                  srcSet="/img/spa_02.webp"
                  type="image/webp"
                />
                <img
                  className="lazy"
                  data-src="/img/spa_02.webp"
                  src="/img/spa_02.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <picture>
                <source
                  data-srcset="/img/spa_01.webp"
                  srcSet="/img/spa_01.webp"
                  type="image/webp"
                />
                <img
                  className="lazy"
                  data-src="/img/spa_01.webp"
                  src="/img/spa_01.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>
            <div className="hero_slider-nav swiper-overlay-nav swiper-nav">
              <a className="swiper-button-prev nav-item">
                <i className="icon-chevron-left" />
              </a>
              <a className="swiper-button-next nav-item" href="#">
                <i className="icon-chevron-right" />
              </a>
            </div>
          </Swiper>
        </div>
      </section>
      <section class="hero hero_dining">
        <div class="container">
          <div class="hero_main">
            <div class="section_header">
             <h1>Fitness</h1>
              <p className="aura_text ">{props.strings["text_288"]}</p>
              <div class="wrapper d-flex">
                <span data-aos="fade-up">
                  <a
                    class="btn "
                    href="https://oceanclub.com.tr/ankara/"
                    target="_blank"
                    className="qr_menu btn"
                  >
                    {props.strings["text_343"]}
                  </a>
                </span>
                <span data-aos="fade-up">
                  <Link
                    key="Spa"
                    class="btn "
                    to={{ pathname: "/gallery", search: "?tab=Spa"}}
                    className="auro_gallery btn"
                  >
                    {props.strings["text_6"]}
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <Swiper
            modules={[Navigation]}
            loop={true}
            className="hero_slider swiper"
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
          >
            {" "}
            <SwiperSlide>
              <picture>
                <source
                  data-srcset="/img/fitness_01.webp"
                  srcSet="/img/fitness_01.webp"
                  type="image/jpg"
                />
                <img
                  className="lazy"
                  data-src="/img/fitness_01.webp"
                  src="/img/fitness_01.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>{" "}
            <SwiperSlide>
              <picture>
                <source
                  data-srcset="/img/fitness_02.webp"
                  srcSet="/img/fitness_02.webp"
                  type="image/webp"
                />
                <img
                  className="lazy"
                  data-src="/img/fitness_02.webp"
                  src="/img/fitness_02.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <picture>
                <source
               data-srcset="/img/fitness_03.webp"
               srcSet="/img/fitness_03.webp"
                  type="image/webp"
                />
                <img
                  className="lazy"
                  data-src="/img/fitness_03.webp"
                  src="/img/fitness_03.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>
            <div className="hero_slider-nav swiper-overlay-nav swiper-nav">
              <a className="swiper-button-prev nav-item">
                <i className="icon-chevron-left" />
              </a>
              <a className="swiper-button-next nav-item" href="#">
                <i className="icon-chevron-right" />
              </a>
            </div>
          </Swiper>
        </div>
      </section>
      <section>
        <Swiper
          modules={[Navigation]}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          effect={"coverflow"}
          centeredSlides={true}
          slideToClickedSlide={true}
          slidesPerView={"auto"}
          loop={true}
          speed={1000}
          autoplay={{ delay: 3000 }}
          coverflowEffect={{
            rotate: 0,
            stretch: 1,
            depth: 500,
            modifier: 1,
            slideShadows: false,
          }}
          className="mySwiper swiper-slider_spa_massage"
        >
          <SwiperSlide className="swiper-slide spa_one">
            <div className="image-container">
              {" "}
              <div className="spa_info">
                <h4> {props.strings["text_119"]}</h4>
                <p>{props.strings["text_120"]}</p>
                <button className="btn spa_menu_btn">
                  {" "}
                  <a
                  href="https://oceanclub.com.tr/ankara/"
                    target="_blank"
                    className="qr_menu"
                  >
                    {props.strings["text_343"]}
                  </a>
                </button>
              </div>
              <img src="/img/spa_swiper_2.png" />{" "}
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide spa_two">
            <div className="image-container">
              <div className="spa_info">
                <h4>{props.strings["text_121"]}</h4>
                <p>{props.strings["text_122"]}</p>
                <button className="btn spa_menu_btn">
                  {" "}
                  <a
                    href="https://oceanclub.com.tr/ankara/"
                    target="_blank"
                    className="qr_menu"
                  >
                    {props.strings["text_343"]}
                  </a>
                </button>
              </div>
              <img src="/img/spa_swiper_3.png" />
            </div>{" "}
          </SwiperSlide>
          <SwiperSlide className="swiper-slide spa_three">
            <div className="image-container">
              <div className="spa_info">
                <h4>{props.strings["text_123"]}</h4>
                <p>{props.strings["text_124"]}</p>
                <button className="btn spa_menu_btn">
                  {" "}
                  <a
                   href="https://oceanclub.com.tr/ankara/"
                    target="_blank"
                    className="qr_menu"
                  >
                    {props.strings["text_343"]}
                  </a>
                </button>
              </div>
              <img src="/img/spa_swiper.png" />
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide spa_three">
            <div className="image-container">
              <div className="spa_info">
                <h4>{props.strings["text_121"]}</h4>
                <p>{props.strings["text_122"]}</p>
                <button className="btn spa_menu_btn">
                  <a
                   href="https://oceanclub.com.tr/ankara/"
                    target="_blank"
                    className="qr_menu"
                  >
                    {props.strings["text_343"]}
                  </a>
                </button>
              </div>
              <img src="/img/spa_swiper_3.png" />
            </div>
          </SwiperSlide>
          <div className="hero_slider-nav swiper-overlay-nav swiper-nav">
            <a className="swiper-button-prev nav-item">
              <i className="icon-chevron-left" />
            </a>
            <a className="swiper-button-next nav-item" href="#">
              <i className="icon-chevron-right" />
            </a>
          </div>
        </Swiper>
      </section>
      <Footer />
    </div>
  );
};

SpaFitness.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(SpaFitness));
