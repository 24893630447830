import React from "react";
import { useState } from "react";
import "../../assets/css/Header/Header.css";
import "../../assets/css/HeaderTwo/HeaderTwo.css"
// import logo from "img/logo/crowne_logo.svg";
import DatePicker from "react-datepicker";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Header = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [count, setCount] = useState(2);
  const [countTwo, setCountTwo] = useState(0);

  const handleOpenDatePicker = () => {
    setIsOpen(!isOpen);
  };

  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const incrementCountTwo = () => {
    setCountTwo(countTwo + 1);
  };

  const decrementCountTwo = () => {
    if (countTwo > 0) {
      setCountTwo(countTwo - 1);
    }
  };

  function handleDateChange(date) {
    // initial change: start by setting the startDate
    if (!startDate && !endDate) {
      setStartDate(date);
      // startDate has been set, set the end date
    } else if (startDate && !endDate) {
      setEndDate(date);
    }

    // user is choosing another range => set the start date
    // and set the endDate back to null
    if (startDate && endDate) {
      setStartDate(date);
      setEndDate(null);
    }
  }

  return (
    <header class="header" data-page="home" data-page-parent="home">
      <div class="container d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap">
      <a class="logo h3" href="/">
          <img
            className="header_logo "
            src="img/logo/crowne_logo.svg"
            alt="Logo"
          />{" "}
        </a>
        <nav class="header_nav text--medium text--md collapse" id="headerMenu"     style={{ width: "60%" }}>
          <ul class="header_nav-list">
            <li class="header_nav-list_item">
              <a
                class="nav-item nav-link"
                href="/"
                data-page="menu"
                data-single="true"
              >
                <span class="nav-item_text">  {props.strings["text_0"]}</span>
              </a>
            </li>

            <li class="header_nav-list_item">
              <a
                class="nav-item nav-link"
                href="/rooms"
                data-page="menu"
                data-single="true"
              >
                <span class="nav-item_text">  {props.strings["text_2"]}</span>
              </a>
            </li>
            <li class="header_nav-list_item">
              <a
                class="nav-item nav-link"
                href="/meeting_events"
                data-page="menu"
                data-single="true"
              >
                <span class="nav-item_text">  {props.strings["text_3"]}</span>
              </a>
            </li>
            <li class="header_nav-list_item">
              <a
                class="nav-item nav-link"
                href="/restaurant"
                data-single="true"
              >
                <span class="nav-item_text">  {props.strings["text_4"]}</span>
              </a>
            </li>
            <li class="header_nav-list_item">
              <a
                class="nav-item nav-link"
                href="/spa_fitness"
                data-page="menu"
                data-single="true"
              >
                <span class="nav-item_text">  {props.strings["text_5"]}</span>
              </a>
            </li>
            <li class="header_nav-list_item">
              <a
                class="nav-item nav-link"
                href="/gallery"
                data-page="menu"
                data-single="true"
              >
                <span class="nav-item_text">  {props.strings["text_6"]}</span>
              </a>
            </li>
            <li class="header_nav-list_item">
              <a
                class="nav-item nav-link"
                href="/contact"
                data-page="menu"
                data-single="true"
              >
                <span class="nav-item_text">  {props.strings["text_7"]}</span>
              </a>
            </li>
          </ul>
        </nav>{" "}

        <nav className="d-flex align-items-center mobile_style" >
        <a
            href="https://crowneplazaankara.com.tr/sanal-tur/"
            target="_blank"
          >
            {" "}
            <img
              className="header_gif_two "
              src="/img/header_gif.gif"
            />
          </a>
          <button onClick={handleOpenDatePicker} className="btn book_button book_button_two">
            <p>{props.strings["text_8"]}</p>
          </button>{" "}
          {isOpen && (
            <DatePicker
              onChange={(date) => handleDateChange(date)}
              selectsStart={true}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              inline={true}
            >
              <div className="row">
                <div className="col-lg-6 col-sm-12 res_adult_book">
                  {" "}
                  <div className="math_section pt-3">
                    <h5 className="pl-2 book_now_title_one">
                      {props.strings["text_103"]}
                    </h5>
                    <div className="d-flex gap-3 center_picker">
                      {" "}
                      <button
                        className="btn btn-decr btn-sm"
                        onClick={decrementCount}
                      >
                        <h5>-</h5>
                      </button>
                      <h4 className="padding_3"> {count}</h4>
                      <button
                        className="btn btn-incr btn-sm"
                        onClick={incrementCount}
                      >
                        <h5>+</h5>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 res_child_book pl-3">
                  {" "}
                  <div className="math_section pt-3">
                    <h5 className="book_now_title_one">{props.strings["text_104"]}</h5>
                    <div className="d-flex gap-3 center_picker">
                      {" "}
                      <button
                        className="btn btn-decr btn-sm"
                        onClick={decrementCountTwo}
                      >
                        <h5>-</h5>
                      </button>
                      <h4 className="padding_3"> {countTwo}</h4>
                      <button
                        className="btn btn-incr btn-sm"
                        onClick={incrementCountTwo}
                      >
                        <h5>+</h5>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center pt-3">
                <button
                   onClick={(e) => {
                    if (!endDate) {
                      Swal.fire({
                        title: 'Crowne Plaza Ankara Hotel', 
                        text: props.strings["text_341"], 
                        icon: 'warning', 
                        confirmButtonText: props.strings["text_342"], 
                        customClass: {
                          popup: 'custom-swal-popup', // Özel sınıf adı
                        },
                      });
                
                      e.preventDefault();
                      return;
                    }
              
                    console.log(startDate);
                    console.log(endDate);
                    const startYear =
                      startDate.getMonth() + "" + startDate.getFullYear();
                    const endYear =
                      endDate.getMonth() + "" + endDate.getFullYear();
                    const endDay = endDate.getDate();
                    const startDay = startDate.getDate();
                    const link =
                      "https://www.ihg.com/crowneplaza/hotels/tr/tr/find-hotels/select-roomrate?qDest=Macun%20Mah.%20171%20Cad.%20No:%202/1,%20Ankara,%20TR&qPt=CASH&qCiD=" +
                      startDay +
                      "&qCoD=" +
                      endDay +
                      "&qCiMy=" +
                      startYear +
                      "&qCoMy=" +
                      endYear +
                      "&qAdlt=" +
                      count +
                      "&qChld=" +
                      countTwo +
                      "&qRms=1&qRtP=6CBARC&qAAR=6CBARC&qSlH=ankcr&srb_u=1&qSrt=sBR&qBrs=6c.hi.ex.sb.ul.ic.cp.cw.in.vn.cv.rs.ki.ma.sp.va.sp.re.vx.nd.ii.sx.we.lx&qWch=0&qSmP=0&qRad=30&qRdU=mi&setPMCookies=false&qpMn=0&qRmFltr=";
                    window.open(link, "_blank");
                  }}
                  className="btn datepicker_btn btn-sm w-100"
                >
                  <a href="" target="_blank">
                    {props.strings["text_8"]}
                  </a>
                </button>
              </div>
            </DatePicker>
          )}
          <div className="language mx-2">
            {props.currentLanguageCode == "tr" ? (
              <>
                <button
                  className="btn datepicker_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    props.dispatch(changeLanguage("en"));
                  }}
                >
                  EN
                </button>
              </>
            ) : (
              <button
                className="btn datepicker_btn"
                onClick={(e) => {
                  e.preventDefault();
                  props.dispatch(changeLanguage("tr"));
                }}
              >
                TR
              </button>
            )}
          </div>
        
        </nav>
       
        <div class="header-second">
          <input class="menu-btn" type="checkbox" id="menu-btn" />
          <label class="menu-icon" htmlFor="menu-btn">
            <span class="navicon"/>
          </label>
          <ul class="menu-second">
      
            <li>
              <Link to="/">  {props.strings["text_0"]}</Link>
            </li>
            <li>
              <Link to="/rooms">  {props.strings["text_2"]}</Link>
            </li>
            <li>
              <Link to="/meeting_events">  {props.strings["text_3"]}</Link>
            </li>
            <li>
              <Link to="/restaurant">  {props.strings["text_4"]}</Link>
            </li>
            <li>
              <Link to="/spa_fitness">  {props.strings["text_5"]}</Link>
            </li>
            <li>
              <Link to="/gallery">  {props.strings["text_6"]}</Link>
            </li>
            <li>
              <Link to="/contact">  {props.strings["text_7"]}</Link>
            </li>
            <li>
            <a
                  href="https://crowneplazaankara.com.tr/sanal-tur/"
                  target="_blank"
                >
                  {" "}
                  <img
                    className="header_scroll_mobile"
                    src="/img/header_gif.gif"
                  />
                </a>
            <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="right_section pt-3 pb-5">
                      <div className="mobil_center d-flex justify-content-center"> <img
                        className="contact_crowne_logo_menu"
                        src="/img/crowne_logo.svg"
                      /></div>
                     
                      <br/>
                      <h6 className="text-black text-center">Macun, 171. Sk. 2/1, 06374</h6>
                      <h6 className="text-black text-center">Yenimahalle/Ankara</h6>
                     
                      <h6>
                        {" "}
                        <a className="black_link text-center tel_link" href="tel:+03123200000">
                          {" "}
                          +90 312 320 00 00
                        </a>
                      </h6>
                      <h6 className="text-center text-black">
                        <a
                          className="contact_mail_link"
                          href="mailto: reservation@crowneplazaankara.com.tr"
                        >
                          reservation@crowneplazaankara.com.tr
                        </a>
                      </h6>
                    

                      <h6 className=" text-center">
                        <a
                          className="contact_mail_link"
                          href="mailto: info@crowneplazaankara.com.tr"
                        >
                          info@crowneplazaankara.com.tr
                        </a>
                      </h6>
                      <div className="d-flex justify-content-center">
                        {" "}
                        <a
                       
                          href="https://www.instagram.com/crowneplazaankara/"
                          target="_blank"
                        >
                          <FaInstagram className="social_media_icon_contact" />
                        </a>
                        <a
                          href="https://www.facebook.com/crowneplazaankarahotel/"
                          target="_blank"
                        >
                          {" "}
                          <FaFacebookSquare className="social_media_icon_contact" />
                        </a>
                        <a
                          href="https://tr.linkedin.com/company/crowne-plaza-ankara"
                          target="_blank"
                        >
                          {" "}
                          <FaLinkedin className="social_media_icon_contact" />
                        </a>
                        <IoLogoWhatsapp className="social_media_icon_contact" />
                      </div>
                    </div>
                  </div>
                
                </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(Header));
