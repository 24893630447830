import * as React from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { BsWifi } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
// import required modules
import { FreeMode } from "swiper";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useState } from "react";

const images = [
  "/img/wedding_angora_swiper.jpg",
  "/img/wedding_angora_swiper_2.jpg",
  "/img/wedding_angora_swiper.jpg",
  "/img/wedding_angora_swiper_2.jpg",
];
const imagesTwo = [
  "/img/ankuva_banket.webp",
  "/img/ankuva_banket_02.webp",
  "/img/ankuva_banket.webp",
  "/img/ankuva_banket_02.webp",
];
const imagesFour = [
  "/img/terra_swiper_4.jpg",
  "/img/terra_swiper_2.jpg",
  "/img/terra_swiper_3.jpg",
  "/img/terra_swiper_4.jpg",
];
const imagesFive = [
  "/img/hera_1.png",
  "/img/hera_2.png",
  "/img/hera_3.png"
];


function MeetingDetailWedding(props) {
  const [isOpen, setIsOpen] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Tabs className="room_tab_detail">
        <TabList className="tab_list">
          <Tab className="tab_one_meet room_tab_pink">
            <h6 className=""> {props.strings["text_137"]}</h6>
          </Tab>
          <Tab className="tab_one_meet room_tab_pink">
            <h6 className=""> {props.strings["text_138"]}</h6>
          </Tab>
          <Tab className="tab_one_meet room_tab_pink">
            <h6 className="">{props.strings["text_143"]}</h6>
          </Tab>
          <Tab className="tab_one_meet room_tab_pink">
            <h6 className="">{props.strings["text_344"]}</h6>
          </Tab>
        </TabList>
        <TabPanel>
          <div className="meeting_inner_res ">
            <div className="row">
              <div className="col-lg-5">
                <div>
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide carousel-fade"
                    data-mdb-ride="carousel"
                  >
                    <div className="carousel-inner mb-5">
                      {images.map((image, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            src={image}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                    <div className="carousel-indicators">
                      {images.map((image, index) => (
                        <button
                          key={index}
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to={index}
                          className={index === photoIndex ? "active" : ""}
                          aria-current={index === photoIndex ? "true" : "false"}
                          aria-label={`Slide ${index + 1}`}
                          style={{ width: 100 }}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${index + 1}`}
                          />
                        </button>
                      ))}
                    </div>
                  </div>

                  {isOpen && (
                    <Lightbox
                      mainSrc={images[photoIndex]}
                      nextSrc={images[(photoIndex + 1) % images.length]}
                      prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + images.length - 1) % images.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                      }
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="meeting_info_section flex-wrap col-12">
                  <div className="meeting_info_section_inner">
                    <h6 className="bold-text">Area m²</h6>
                    <p>760</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/8.png"></img>
                    <h6 className="bold-text">U Shape</h6>
                    <p>120</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/4.png"></img>
                    <h6 className="bold-text">Classroom</h6>
                    <p>550</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/2.png"></img>
                    <h6 className="bold-text">Boardroom</h6>
                    <p>150</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/7.png"></img>
                    <h6 className="bold-text">Theatre</h6>
                    <p>900</p>
                  </div>
                
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/1.png"></img>
                    <h6 className="bold-text">Banquet</h6>
                    <p>650</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/3.png"></img>
                    <h6 className="bold-text">Cabaret</h6>
                    <p>385</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/6.png"></img>
                    <h6 className="bold-text">Cocktail</h6>
                    <p>1000</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/5.png"></img>
                    <h6 className="bold-text">A/C</h6>
                    <p>Yes</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/4.png"></img>
                    <h6 className="bold-text">Daylight</h6>
                    <p>-</p>
                  </div>
             
                  <div className="meeting_info_section_inner">
                    <h6 className="bold-text">Floor</h6>
                    <p>Second Floor</p>
                  </div>
            </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="meeting_inner_res ">
            <div className="row">
              <div className="col-lg-5">
                <div>
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide carousel-fade"
                    data-mdb-ride="carousel"
                  >
                    <div className="carousel-inner mb-5">
                      {imagesTwo.map((image, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            src={image}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                    <div className="carousel-indicators">
                      {imagesTwo.map((image, index) => (
                        <button
                          key={index}
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to={index}
                          className={index === photoIndex ? "active" : ""}
                          aria-current={index === photoIndex ? "true" : "false"}
                          aria-label={`Slide ${index + 1}`}
                          style={{ width: 100 }}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${index + 1}`}
                          />
                        </button>
                      ))}
                    </div>
                  </div>

                  {isOpen && (
                    <Lightbox
                      mainSrc={imagesTwo[photoIndex]}
                      nextSrc={imagesTwo[(photoIndex + 1) % imagesTwo.length]}
                      prevSrc={
                        imagesTwo[
                          (photoIndex + imagesTwo.length - 1) % imagesTwo.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + imagesTwo.length - 1) % imagesTwo.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % imagesTwo.length)
                      }
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="meeting_info_section flex-wrap col-12">
                  <div className="meeting_info_section_inner">
                    <h6 className="bold-text">Area m²</h6>
                    <p>400</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/8.png"></img>
                    <h6 className="bold-text">U Shape</h6>
                    <p>80</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/4.png"></img>
                    <h6 className="bold-text">Classroom</h6>
                    <p>240</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/2.png"></img>
                    <h6 className="bold-text">Boardroom</h6>
                    <p>100</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/7.png"></img>
                    <h6 className="bold-text">Theatre</h6>
                    <p>430</p>
                  </div>
              
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/1.png"></img>
                    <h6 className="bold-text">Banquet</h6>
                    <p>290</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/3.png"></img>
                    <h6 className="bold-text">Cabaret</h6>
                    <p>170</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/6.png"></img>
                    <h6 className="bold-text">Cocktail</h6>
                    <p>450</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/5.png"></img>
                    <h6 className="bold-text">A/C</h6>
                    <p>Yes</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/4.png"></img>
                    <h6 className="bold-text">Daylight</h6>
                    <p>Yes</p>
                  </div>
               
                  <div className="meeting_info_section_inner">
                    <h6 className="bold-text">Floor</h6>
                    <p>Second Floor</p>
                  </div>
               </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="meeting_inner_res ">
            <div className="row">
              <div className="col-lg-5">
                <div>
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide carousel-fade"
                    data-mdb-ride="carousel"
                  >
                    <div className="carousel-inner mb-5">
                      {imagesFour.map((image, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            src={image}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                    <div className="carousel-indicators">
                      {imagesFour.map((image, index) => (
                        <button
                          key={index}
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to={index}
                          className={index === photoIndex ? "active" : ""}
                          aria-current={index === photoIndex ? "true" : "false"}
                          aria-label={`Slide ${index + 1}`}
                          style={{ width: 100 }}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${index + 1}`}
                          />
                        </button>
                      ))}
                    </div>
                  </div>

                  {isOpen && (
                    <Lightbox
                      mainSrc={imagesFour[photoIndex]}
                      nextSrc={imagesFour[(photoIndex + 1) % imagesFour.length]}
                      prevSrc={
                        imagesFour[
                          (photoIndex + imagesFour.length - 1) %
                            imagesFour.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + imagesFour.length - 1) %
                            imagesFour.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % imagesFour.length)
                      }
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="meeting_info_section flex-wrap col-12">
                  <div className="meeting_info_section_inner">
                    <h6 className="bold-text">Area m²</h6>
                    <p>215</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/8.png"></img>
                    <h6 className="bold-text">U Shape</h6>
                    <p>-</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/4.png"></img>
                    <h6 className="bold-text">Classroom</h6>
                    <p>140</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/2.png"></img>
                    <h6 className="bold-text">Boardroom</h6>
                    <p>-</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/7.png"></img>
                    <h6 className="bold-text">Theatre</h6>
                    <p>250</p>
                  </div>
              
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/1.png"></img>
                    <h6 className="bold-text">Banquet</h6>
                    <p>110</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/3.png"></img>
                    <h6 className="bold-text">Cabaret</h6>
                    <p>70</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/6.png"></img>
                    <h6 className="bold-text">Cocktail</h6>
                    <p>200</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/5.png"></img>
                    <h6 className="bold-text">A/C</h6>
                    <p>Yes</p>
                  </div>
                  <div className="meeting_info_section_inner">
                    <img className="icon_desk_style" src="/img/4.png"></img>
                    <h6 className="bold-text">Daylight</h6>
                    <p>Yes</p>
                  </div>
             
                  <div className="meeting_info_section_inner">
                    <h6 className="bold-text">Floor</h6>
                    <p>Second Floor</p>
                  </div>
             </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="meeting_inner_res ">
            <div className="row">
            <div className="col-lg-5">
                <div>
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide carousel-fade"
                    data-mdb-ride="carousel"
                  >
                    <div className="carousel-inner mb-5">
                      {imagesFive.map((image, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            src={image}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                    <div
                      className="carousel-indicators"
                      
                    >
                      {imagesFive.map((image, index) => (
                        <button
                          key={index}
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to={index}
                          className={index === photoIndex ? "active" : ""}
                          aria-current={index === photoIndex ? "true" : "false"}
                          aria-label={`Slide ${index + 1}`}
                          style={{ width: 100 }}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${index + 1}`}
                          />
                        </button>
                      ))}
                    </div>
                  </div>

                  {isOpen && (
                    <Lightbox
                      mainSrc={imagesFive[photoIndex]}
                      nextSrc={imagesFive[(photoIndex + 1) % imagesFive.length]}
                      prevSrc={
                        imagesFive[
                          (photoIndex + imagesFive.length - 1) %
                            imagesFive.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + imagesFive.length - 1) %
                            imagesFive.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % imagesFive.length)
                      }
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
            
            <div
              
              className="meeting_info_section flex-wrap col-12"
            >
              <div className="meeting_info_section_inner">
                <h6 className="bold-text">Area m²</h6>
                <p>135</p>
              </div>
              <div className="meeting_info_section_inner">
                 <img className= "icon_desk_style"src="/img/8.png"></img>
                <h6 className="bold-text">U Shape</h6>
                <p>54</p>
              </div>
              <div className="meeting_info_section_inner">
               <img className= "icon_desk_style"src="/img/4.png"></img>
                <h6 className="bold-text">Classroom</h6>
                <p>96</p>
              </div>
              <div className="meeting_info_section_inner">
                <img className= "icon_desk_style"src="/img/2.png"></img>
                <h6 className="bold-text">Boardroom</h6>
                <p>56</p>
              </div>
              <div className="meeting_info_section_inner">
              <img className= "icon_desk_style"src="/img/7.png"></img>
                <h6 className="bold-text">Theatre</h6>
                <p>180</p>
              </div>
          
           
              <div className="meeting_info_section_inner">
                <img className= "icon_desk_style"src="/img/1.png"></img>
                <h6 className="bold-text">Banquet</h6>
                <p>110</p>
              </div>
              <div className="meeting_info_section_inner">
                <img className= "icon_desk_style"src="/img/3.png"></img>
                <h6 className="bold-text">Cabaret</h6>
                <p>70</p>
              </div>
              <div className="meeting_info_section_inner">
                 <img className= "icon_desk_style"src="/img/6.png"></img>
                <h6 className="bold-text">Cocktail</h6>
                <p>165</p>
              </div>
              <div className="meeting_info_section_inner">
                 <img className= "icon_desk_style"src="/img/5.png"></img>
                <h6 className="bold-text">A/C</h6>
                <p>Yes</p>
              </div>
              <div className="meeting_info_section_inner">
                 <img className= "icon_desk_style"src="/img/4.png"></img>
                <h6 className="bold-text">Daylight</h6>
                <p>Yes</p>
              </div>
         
            
              <div className="meeting_info_section_inner">
                <h6 className="bold-text">Floor</h6>
                <p>1</p>
              </div>
         </div>
          </div>
            </div>
           
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
}

MeetingDetailWedding.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(MeetingDetailWedding));
