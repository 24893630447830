import React from "react";
import "../../assets/css/MeetingEvents/MeetingEvents.css";

const Table = () => {
  return (
    <div className="meeting_table">
        <table class="table table-hover">
        
        <thead>
          <tr className="tr_header">
            <th scope="col">Meeting Space</th>
            <th scope="col">Area m²</th>
            <th scope="col">U-Shape</th>
            <th scope="col">Classroom</th>
            <th scope="col">Boardroom</th>
            <th scope="col">Theatre</th>
            <th scope="col">Banquet</th>
            <th scope="col">Cabaret</th>
            <th scope="col">Cocktail</th>
            <th scope="col">A/C</th>
            <th scope="col">Daylight</th>
            <th scope="col">Floor</th>
          

          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Angora Ballroom</th>
            <td>760</td>
            <td>120</td>
            <td>550</td>
            <td>150</td>
            <td>900</td>
            <td>650</td>
            <td>385</td>
            <td>1000</td>
            <td>Yes</td>
            <td>-</td>
            <td>2</td>
          </tr>
          <tr>
            <th scope="row">Angora Ballroom 1</th>
            <td>380</td>
            <td>90</td>
            <td>250</td>
            <td>105</td>
            <td>420</td>
            <td>300</td>
            <td>175</td>
            <td>450</td>
            <td>Yes</td>
            <td>-</td>
            <td>2</td>
          </tr>
          <tr>
            <th scope="row">Angora Ballroom 2</th>
            <td>380</td>
            <td>90</td>
            <td>250</td>
            <td>105</td>
            <td>420</td>
            <td>300</td>
            <td>175</td>
            <td>450</td>
            <td>Yes</td>
            <td>-</td>
            <td>2</td>
          </tr>
          <tr>
            <th scope="row">Ankuva Ballroom</th>
            <td>400</td>
            <td>80</td>
            <td>240</td>
            <td>100</td>
            <td>430</td>
            <td>290</td>
            <td>170</td>
            <td>450</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>2</td>
          </tr>
          <tr>
            <th scope="row">Ankuva Ballroom 1</th>
            <td>200</td>
            <td>55</td>
            <td>100</td>
            <td>70</td>
            <td>200</td>
            <td>130</td>
            <td>90</td>
            <td>200</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>2</td>
          </tr>
          <tr>
            <th scope="row">Ankuva Ballroom 2</th>
            <td>200</td>
            <td>55</td>
            <td>100</td>
            <td>70</td>
            <td>200</td>
            <td>130</td>
            <td>90</td>
            <td>200</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>2</td>
          </tr>

          <tr>
            <th scope="row">Ankyra Meeting Room</th>
            <td>70</td>
            <td>28</td>
            <td>56</td>
            <td>36</td>
            <td>72</td>
            <td>-</td>
            <td>-</td>
            <td>100</td>
            <td>Yes</td>
            <td>-</td>
            <td>2</td>
          </tr>
          <tr>
            <th scope="row">Convention Terrace</th>
            <td>115</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>120</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>2</td>
          </tr>

          <tr>
            <th scope="row">Convention Foyer</th>
            <td>366</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>400</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>2</td>
          </tr>
          <tr>
            <th scope="row">Aura Restaurant</th>
            <td>420</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>250</td>
            <td>-</td>
            <td>300</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>2</td>
          </tr>
          <tr>
            <th scope="row">Aura PDR</th>
            <td>22</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>10</td>
            <td>-</td>
            <td>-</td>
            <td>Yes</td>
            <td>-</td>
            <td>2</td>
          </tr>

          <tr>
            <th scope="row">Nora Meeting Room</th>
            <td>55</td>
            <td>21</td>
            <td>36</td>
            <td>20</td>
            <td>45</td>
            <td>-</td>
            <td>-</td>
            <td>40</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>1</td>
          </tr>
          <tr>
            <th scope="row">Nora VIP Room</th>
            <td>23</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>Yes</td>
            <td>-</td>
            <td>1</td>
          </tr>
          <tr>
            <th scope="row">Vera Meeting Room</th>
            <td>50</td>
            <td>21</td>
            <td>36</td>
            <td>20</td>
            <td>45</td>
            <td>-</td>
            <td>-</td>
            <td>40</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>1</td>
          </tr>
          <tr>
            <th scope="row">Mira Meeting Room</th>
            <td>50</td>
            <td>21</td>
            <td>36</td>
            <td>20</td>
            <td>45</td>
            <td>-</td>
            <td>-</td>
            <td>40</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>1</td>
          </tr>
          <tr>
            <th scope="row">Mezzanine Foyer</th>
            <td>120</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>100</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>1</td>
          </tr>
          <tr>
            <th scope="row">Terra Meeting Room</th>
            <td>215</td>
            <td>-</td>
            <td>140</td>
            <td>-</td>
            <td>250</td>
            <td>110</td>
            <td>70</td>
            <td>200</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>1</td>
          </tr>
          <tr>
            <th scope="row">Terra Meeting Room 1</th>
            <td>115</td>
            <td>37</td>
            <td>80</td>
            <td>45</td>
            <td>140</td>
            <td>35</td>
            <td>60</td>
            <td>100</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>1</td>
          </tr>
          <tr>
            <th scope="row">Terra Meeting Room 2</th>
            <td>100</td>
            <td>37</td>
            <td>60</td>
            <td>45</td>
            <td>140</td>
            <td>35</td>
            <td>50</td>
            <td>100</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>1</td>
          </tr>
          <tr>
            <th scope="row">Terra Foyer</th>
            <td>138</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>120</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>1</td>
          </tr>
          <tr>
            <th scope="row">Terra Terrace</th>
            <td>25</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>30</td>
            <td>-</td>
            <td>Yes</td>
            <td>1</td>
          </tr>
          <tr>
            <th scope="row">Hera Meeting Room</th>
            <td>135</td>
            <td>54</td>
            <td>96</td>
            <td>56</td>
            <td>180</td>
            <td>110</td>
            <td>70</td>
            <td>165</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>1</td>
          </tr>
          <tr>
            <th scope="row">Dora Meeting Room</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <th scope="row">Premium Lounge</th>
            <td>40</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>30</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>20</td>
          </tr>
          <tr>
            <th scope="row">Premium Lounge Terrace</th>
            <td>13</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>30</td>
            <td>-</td>
            <td>Yes</td>
            <td>20</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
